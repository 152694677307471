import React from 'react';
import Header from '../Components/Header';
import Graphs from '../Components/Graphicals/Graphs';

const Dashboard = () => {
return (
<>
<div id="layout-wrapper">
<Header title="Dashboard" />

<div class="main-content">

<div class="page-content">
<div class="container-fluid">

<div class="row">
<div class="col-xl-3 col-sm-6">
<div class="card mini-stat bg-primary">
<div class="card-body mini-stat-img">
<div class="mini-stat-icon">
<i class="mdi mdi-cube-outline float-end"></i>
</div>
<div class="text-white">
<h6 class="text-uppercase mb-3 font-size-16 text-white">Expense</h6>
<h2 class="mb-4 text-white">0</h2>
<span class="badge bg-info"> 0% </span> <span class="ms-2">From previous period</span>
</div>
</div>
</div>
</div>
<div class="col-xl-3 col-sm-6">
<div class="card mini-stat bg-primary">
<div class="card-body mini-stat-img">
<div class="mini-stat-icon">
<i class="mdi mdi-buffer float-end"></i>
</div>
<div class="text-white">
<h6 class="text-uppercase mb-3 font-size-16 text-white">Revenue</h6>
<h2 class="mb-4 text-white">0</h2>
<span class="badge bg-danger"> 0% </span> <span class="ms-2">From previous period</span>
</div>
</div>
</div>
</div>
<div class="col-xl-3 col-sm-6">
<div class="card mini-stat bg-primary">
<div class="card-body mini-stat-img">
<div class="mini-stat-icon">
<i class="mdi mdi-tag-text-outline float-end"></i>
</div>
<div class="text-white">
<h6 class="text-uppercase mb-3 font-size-16 text-white">Sales</h6>
<h2 class="mb-4 text-white">0</h2>
<span class="badge bg-warning"> 0% </span> <span class="ms-2">From previous period</span>
</div>
</div>
</div>
</div>
<div class="col-xl-3 col-sm-6">
<div class="card mini-stat bg-primary">
<div class="card-body mini-stat-img">
<div class="mini-stat-icon">
<i class="mdi mdi-briefcase-check float-end"></i>
</div>
<div class="text-white">
<h6 class="text-uppercase mb-3 font-size-16 text-white">Fixed Assets</h6>
<h2 class="mb-4 text-white">0</h2>
<span class="badge bg-info"> 0% </span> <span class="ms-2">From previous period</span>
</div>
</div>
</div>
</div>


<div class="col-xl-3 col-sm-6">
<div class="card mini-stat bg-primary">
<div class="card-body mini-stat-img">
<div class="mini-stat-icon">
<i class="mdi mdi-briefcase-check float-end"></i>
</div>
<div class="text-white">
<h6 class="text-uppercase mb-3 font-size-16 text-white">Cash In Hand</h6>
<h2 class="mb-4 text-white">0</h2>
<span class="badge bg-info"> 0% </span> <span class="ms-2">From previous period</span>
</div>
</div>
</div>
</div>

<div class="col-xl-3 col-sm-6">
<div class="card mini-stat bg-primary">
<div class="card-body mini-stat-img">
<div class="mini-stat-icon">
<i class="mdi mdi-briefcase-check float-end"></i>
</div>
<div class="text-white">
<h6 class="text-uppercase mb-3 font-size-16 text-white">Cash In Bank</h6>
<h2 class="mb-4 text-white">0</h2>
<span class="badge bg-info"> 0% </span> <span class="ms-2">From previous period</span>
</div>
</div>
</div>
</div>

<div class="col-xl-3 col-sm-6">
<div class="card mini-stat bg-primary">
<div class="card-body mini-stat-img">
<div class="mini-stat-icon">
<i class="mdi mdi-briefcase-check float-end"></i>
</div>
<div class="text-white">
<h6 class="text-uppercase mb-3 font-size-16 text-white">Current Liabilities</h6>
<h2 class="mb-4 text-white">0</h2>
<span class="badge bg-info"> 0% </span> <span class="ms-2">From previous period</span>
</div>
</div>
</div>
</div>

<div class="col-xl-3 col-sm-6">
<div class="card mini-stat bg-primary">
<div class="card-body mini-stat-img">
<div class="mini-stat-icon">
<i class="mdi mdi-briefcase-check float-end"></i>
</div>
<div class="text-white">
<h6 class="text-uppercase mb-3 font-size-16 text-white">Total Receivables</h6>
<h2 class="mb-4 text-white">0</h2>
<span class="badge bg-info"> 0% </span> <span class="ms-2">From previous period</span>
</div>
</div>
</div>
</div>

</div>


</div>
</div>


</div>

</div>
</>
)
}

export default Dashboard