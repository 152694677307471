import React from 'react';
import { Link } from 'react-router-dom';

const Login = () => {
return (
<>
<div class="account-pages my-5 pt-sm-5">
<div class="container">
<div class="row justify-content-center">
<div class="col-md-8 col-lg-6 col-xl-5">
<div class="card overflow-hidden">
<div class="card-body pt-0">

<h3 class="text-center mt-5 mb-4">
<a href="index.html" class="d-block auth-logo">
<img src="FrontAsset/assets/images/logo-dark.png" alt="" height="30" class="auth-logo-dark" />
<img src="FrontAsset/assets/images/logo-light.png" alt="" height="30" class="auth-logo-light" />
</a>
</h3>

<div class="p-3">
<form class="form-horizontal mt-4">
<div class="mb-3">
<label for="username">Username</label>
<input type="text" class="form-control" id="username" placeholder="Enter username" />
</div>
<div class="mb-3">
<label for="userpassword">Password</label>
<input type="password" class="form-control" id="userpassword" placeholder="Enter password" />
</div>
<div class="mb-3 row mt-4">
<div class="col-6">
<div class="form-check">
    <input type="checkbox" class="form-check-input" id="customControlInline" />
    <label class="form-check-label" for="customControlInline">Remember me
    </label>
</div>
</div>
<div class="col-6 text-end">
<Link to="/dashboard"><button class="btn btn-primary w-md waves-effect waves-light" type="button">Log In</button></Link>
</div>
</div>
</form>
</div>
</div>
</div>

</div>
</div>
</div>
</div>
</>
)
}

export default Login