import React from 'react'
import { BrowserRouter , Routes , Route } from "react-router-dom";
import Login from '../Pages/Login';
import Dashboard from '../Pages/Dashboard';

const Routers = () => {
  return (
   <BrowserRouter>
    <Routes>
        <Route path="/" element={<Login />}  />
        <Route path="/dashboard" element={<Dashboard />}  />
        <Route path="/company" element={<Dashboard />}  />
    </Routes>
   </BrowserRouter>
  )
}

export default Routers