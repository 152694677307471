import React from 'react'
import { Link } from 'react-router-dom'

const Header = ({title}) => {
return (
<>
<header id="page-topbar">
<div className="navbar-header">
<div className="container-fluid">
<div className="float-start">
<div className="navbar-brand-box">
<Link to="/dashboard" className="logo logo-dark">
<span className="logo-sm">
<img src="FrontAsset/assets/images/logoFinal.png" alt="" width="100" />
</span>
<span className="logo-lg">
<img src="FrontAsset/assets/images/logoFinal.png" alt="" width="140" />
</span>
</Link>

<Link to="/dashboard" className="logo logo-light">
<span className="logo-sm">
<img src="FrontAsset/assets/images/logoFinal.png" alt="" height="22" />
</span>
<span className="logo-lg">
<img src="FrontAsset/assets/images/logoFinal.png" alt="" height="19" />
</span>
</Link>
</div>

<button type="button" className="btn btn-sm px-3 font-size-24 d-lg-none header-item waves-effect waves-light" data-bs-toggle="collapse" data-bs-target="#topnav-menu-content">
<i className="mdi mdi-menu"></i>
</button>
</div>

<div className="float-end">

<form className="app-search d-none d-lg-inline-block">
<div className="position-relative">
<input type="text" className="form-control" placeholder="Search..." />
<span className="fa fa-search"></span>
</div>
</form>

<div className="dropdown d-inline-block d-lg-none ms-2">
<button type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<i className="mdi mdi-magnify"></i>
</button>
<div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">

<form className="p-3">
<div className="form-group m-0">
<div className="input-group">
<input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
<div className="input-group-append">
<button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
</div>
</div>
</div>
</form>
</div>
</div>



<div className="dropdown d-inline-block ms-1">
<button type="button" className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown"
data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<i className="ti-bell"></i>
<span className="badge bg-danger rounded-pill">0</span>
</button>
<div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
aria-labelledby="page-header-notifications-dropdown">
<div className="p-3">
<div className="row align-items-center">
<div className="col">
<h5 className="m-0"> Notifications (0) </h5>
</div>
</div>
</div>
<div data-simplebar style={{maxHeight:'230px'}}>
</div>
<div className="p-2 border-top">
<Link className="btn btn-sm btn-link font-size-14 w-100 text-center" to="#">
View all
</Link>
</div>
</div>
</div>

<div className="dropdown d-inline-block">
<button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<img className="rounded-circle header-profile-user" src="FrontAsset/assets/images/users/user-4.jpg"
alt="Header Avatar" />
</button>
<div className="dropdown-menu dropdown-menu-end">
<Link className="dropdown-item" to="/profile"><i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1"></i> Profile</Link>
<div className="dropdown-divider"></div>
<Link className="dropdown-item text-danger" to="/logout"><i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger"></i> Logout</Link>
</div>
</div>

</div>
</div>
</div>

<div className="top-navigation">
<div className="page-title-content">
<div className="container-fluid">
<div className="row">
<div className="col-sm-6">
<div className="page-title-box">
<h4>{title}</h4>
</div>
</div>
<div className="col-sm-6">

</div>
</div>

</div>
</div>

<div className="container-fluid">
<div className="topnav">
<nav className="navbar navbar-light navbar-expand-lg topnav-menu">

<div className="collapse navbar-collapse" id="topnav-menu-content">
<ul className="navbar-nav">

<li className="nav-item dropdown">
<Link className="nav-link dropdown-toggle arrow-none" to="#" id="topnav-more" role="button"
data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<i className="ti-menu-alt"></i>Parameters
</Link>
<div className="dropdown-menu" aria-labelledby="topnav-more">
<Link to="/company" className="dropdown-item">Company</Link>
<Link to="/bank-accounts" className="dropdown-item">Bank Accounts</Link>
<Link to="/bank-accounts" className="dropdown-item">Chart of Accounts</Link>
<Link to="/bank-accounts" className="dropdown-item">Dimensions</Link>

</div>
</li>



<li className="nav-item dropdown">
<Link className="nav-link " to="#">
<i className="ti-receipt"></i>Transaction Register 
</Link>
</li>


<li className="nav-item dropdown">
<Link className="nav-link dropdown-toggle arrow-none" to="#" id="topnav-forms" role="button"
data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<i className="ti-receipt"></i>Cheque Book Register 
</Link>
<div className="dropdown-menu dropdown-menu-left" aria-labelledby="topnav-forms">
<Link to="/gl-report" className="dropdown-item">Cheque Book Register</Link>
<Link to="/trail-balance" className="dropdown-item">Cheque Issuance Register</Link>
<Link to="/income-statement" className="dropdown-item">Post Dated Cheque (PDC)</Link>
</div>
</li>

<li className="nav-item dropdown">
<Link className="nav-link " to="#">
<i className="ti-receipt"></i>Liability Register 
</Link>
</li>

<li className="nav-item dropdown">
<Link className="nav-link " to="#">
<i className="ti-receipt"></i>Recievable Register
</Link>
</li>

<li className="nav-item dropdown">
<Link className="nav-link " to="#">
<i className="ti-receipt"></i>Reports
</Link>
</li>

<li className="nav-item dropdown">
<Link className="nav-link " to="#">
<i className="ti-receipt"></i>Documents
</Link>
</li>

{/* 
<li className="nav-item dropdown">
<Link className="nav-link dropdown-toggle arrow-none" to="#" id="topnav-forms" role="button"
data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
<i className="ti-receipt"></i>Liability Register 
</Link>
<div className="dropdown-menu dropdown-menu-left" aria-labelledby="topnav-forms">
<Link to="/recievable-register" className="dropdown-item">Recievable Register</Link>
<Link to="/reports" className="dropdown-item">Reports</Link>
<Link to="/documents" className="dropdown-item">Documents</Link>
</div>
</li> */}


</ul>
</div>
</nav>
</div>
</div>
</div>


</header>
</>
)
}

export default Header