import React from 'react'
import Routers from './Routes/Routers';
import './App.scss';
function App() {
  return (
    <Routers />
  );
}

export default App;
